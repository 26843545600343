<template>
	 <main>
        <HeaderTab :title="$t('monte.stock_semence_local')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">

export default {
	name: 'StockLocal',
	mixins: [],
	data () {
		return {
			param_tab: '',
            actual_tab: 'visualisationStock',
            default_tab: 'visualisationStock',
            tabsToReload: [],
		}
	},

	mounted() {
		this.param_tab = this.$route.params.actual_tab
	},

	methods: {

	},

	computed:{
        swiper_tabs() {
            return [{
                id: 'visualisationStock',
                label: 'monte.stock_semence.visualisation_stock',
                active: false,
                href: 'visualisationStock'
            },
            {
                id: 'planCuve',
                label: 'monte.stock_semence.plan_cuve',
                active: false,
                href: 'planCuve',
            },
            {
                id: 'fluxStock',
                label: 'monte.stock_semence.flux_stock',
                active: false,
                href: 'fluxStock',
            }]
        },
    },

	components: {
        HeaderTab: () => import('@/components/HeaderTab'),
	}
}

</script>